import React from "react"
import { graphql } from "gatsby"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"
import RubyConvert from "../../../components/RubyConvert"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagMm from "../../../images/flag_mm.svg"
import FlagJp from "../../../images/flag_jp.svg"
import FaceImg01 from "../../../images/interview/01/face_01.jpg"
import FaceImg02 from "../../../images/interview/01/face_02.jpg"
import css from "../stylesheets/interview.module.scss"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`わからないことを教えあいながら働く | INTERVIEW`} />
      <LayoutInterview lang={`ja`}>
        <Info2column id={`interview-01`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>わからないことを教えあいながら働く</h3>
            <p>
              山崎僚恵
              <small>さん（23歳）</small>× ジンジンモー
              <small>さん（27歳）</small>
            </p>
            <p>
              ジンジンモーさんは
              <AnchorLink
                className={`linkUnderline`}
                to="/ja/basic-information/kaigo/#section-2"
              >
                技能実習生
              </AnchorLink>
              として、北海道札幌市にあるグループホーム『
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  <RubyConvert>{`{[満快/まんかい]のふる[郷/さと]}{さくら[発寒/はっさむ]}`}</RubyConvert>
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>働いている施設</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>
              』にミャンマーからやって来ました。彼女が働きはじめて半年後に、新入社員としてやってきたのが山崎
              <ruby>
                <span className={`rb`}>僚恵</span>
                <rt>ともえ</rt>
              </ruby>
              さん。年齢も仕事の経験も近い彼女たちは、互いに助けあいながら働いています。「仕事が楽しい」と笑顔で話すふたりに、日々の仕事や暮らしについて聞きました。
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>ジンジンモーさん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    さくらCSホールディングス株式会社
                    グループホーム『満快のふる郷 さくら発寒』 介護職員
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img src={FlagMm} alt="ミャンマー国旗" className={`flag`} />
                    ミャンマー・バゴー
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>27歳（1993年生まれ） </td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>
                    ダゴン大学 産業化学学部を卒業後、ヤンゴン市 公衆衛生指導員
                  </td>
                </tr>
                <tr>
                  <th>
                    入国時の
                    <br />
                    在留資格
                  </th>
                  <td>技能実習</td>
                </tr>
                <tr>
                  <th>来日</th>
                  <td>2019年1月</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>名前</th>
                  <td>山崎僚恵さん</td>
                </tr>
                <tr>
                  <th>勤務先</th>
                  <td>
                    さくらCSホールディングス株式会社
                    グループホーム『満快のふる郷 さくら発寒』 介護職員
                  </td>
                </tr>
                <tr>
                  <th>出身地</th>
                  <td>
                    <img src={FlagJp} alt="日本国旗" className={`flag`} />
                    北海道札幌市
                  </td>
                </tr>
                <tr>
                  <th>年齢</th>
                  <td>23歳（1996年生まれ）</td>
                </tr>
                <tr>
                  <th>経歴</th>
                  <td>
                    北星学園大学 社会福祉学部
                    福祉心理学科卒業後、CSホールディングス株式会社
                  </td>
                </tr>
                <tr>
                  <th>入社</th>
                  <td>2019年4月</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`介護の仕事をやってみてどうですか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                わたしは子どもの頃からおじいちゃん、おばあちゃんと暮らしていて、頭を洗ったり、体をきれいに拭いたりなどのお手伝いをしていました。だから、介護の仕事をしていると懐かしいですね。わたしのおばあちゃんも認知症だったので、利用者さんたちとのやりとりは似ていると感じます。
                <br />
                 介護の仕事は、利用者さんが自分でできないことをお手伝いして、できるように支える仕事。仏教国のミャンマーでは、相手ができないことを手伝うのは「
                <ruby>
                  <span className={`rb`}>功徳</span>
                  <rt>くどく</rt>
                </ruby>
                」と言われています。たとえば、おじいちゃんやおばあちゃんを世話すると、自分が年をとったときに、子どもたちが世話をしてくれる。世のため、人のためになる行いは、循環すると考えられています。介護は、働きながら功徳を積めるので、心の健康を感じます。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                わたしは、介護は「お世話をする」というより、利用者さんのことを隣で支えるイメージがあります。わたしたちの施設では介護者のことをパートナーと呼んでいるんですよ。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                一人ひとりがどうやったら笑顔になるのか、暮らしやすくなるのかを、毎日話しあっていますよね。 
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                まず何が好きか、何をしたいかを利用者さんに聞くところからはじまります。
                <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                  <span>
                    ラジオ体操
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>ラジオ体操</h3>
                    <p>
                      体力をつけ、健康になることを目的とした体操です。ピアノの伴奏にあわせて、身体を動かします。1928年に誕生し、ラジオで放送されたことで、日本全国に広まりました。学校の授業で教わるため、日本人のほとんどがラジオ体操をすることができます。
                      <a
                        href={`https://www.youtube.com/watch?v=feSVtC1BSeQ`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        <br />
                        NHK「ラジオ体操」
                      </a>
                    </p>
                  </div>
                </ModalLink>
                が好きな人もいるし、折り紙が好きな人、歌を歌うのが好きな人もいます。
              </p>
              <p>
                好きなことだけではなくて、たとえば部屋の模様替えをすることで利用者さんがいきいきすることもありますね。どうしたらひとりで歩いてトイレに行けるか、障害物をなくす部屋のレイアウトについて話しあうこともあります。
              </p>
              <p>
                わたしも仕事に少しずつ慣れてきましたが、ジンジンモーさんの方が半年先輩で、すごく頼りになるんですよ。よく介護の技術について教えてもらいます。わたしは代わりに日本語を教える。わからないことを教えあっていますね。でも、ふたりとも苦手なのが料理（笑）。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  そうですね（笑）。わたしたちの施設では、介護者が
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      食事
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>食事</h3>
                      <p>
                        認知症の高齢者が少人数で暮らすグループホームでは、栄養バランスを考えたメニューをもとに、介護者、ときには利用者が自分たちで食事をつくります。ジンジンモーさんの働く施設では、利用者と介護者が一緒にごはんを食べます。大規模な施設の場合は、専門の調理師が食事をつくります。
                      </p>
                    </div>
                  </ModalLink>
                  をつくるんです。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`山崎 さん`}
              >
                <p>
                  ジンジンモーさんは日本料理を知らないし、わたしは料理をしてこなかったので、材料を前に、ふたりで「どうしよう」って相談しながらつくっています（笑）。昨日は一緒に千切りをしました。
                </p>
              </CommentText>

              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>千切り、乱切りなどの切り方の違いは難しいので練習中です！</p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`働いている施設の特徴は何ですか？ `}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image5.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`山崎 さん`}
              >
                <p>
                  わたしたちの施設では地域との関係を大事にしています。たとえば、近所の電気屋さんにはいつも地域の人たちが集まっていて、利用者さんも遊びに出かけるんですよ。「いまひとりで出かけて行った人がいたけど、大丈夫？」と電話をくれるなど、いつもわたしたちを見守ってくれています。 
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  日本に来るまでは、こんなに地域の人とかかわりがあるとは思いませんでした。施設に初めて来た日に、施設長と一緒に近所の人たちにあいさつをして回りました。「ミャンマーから技能実習生として来たので、どうぞよろしくお願いします」と紹介してもらいました。すごく嬉しくて、その日のことはよく覚えています。
                </p>
                <p>
                  近所の人たちとは、毎日仕事の行き帰りにあいさつをします。「家で食べてね」と畑で育てたジャガイモやブドウをもらうこともあります。みなさん「何かあったらいつでも言ってね」と言ってくれるんですよ。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                利用者さんたちが施設の外に出て、地域の人とかかわれるように、お出かけの計画を立てていろいろな場所に出かけますね。
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    認知症カフェ
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>認知症カフェ</h3>
                    <p>
                      認知症の人、介護する家族、地域住民、介護などの専門職の人などが気軽に集まり、情報交換する場所として、日本全国で開催されています。認知症になると外出する機会が減り、閉じこもりやすくなります。認知症カフェは、そんな認知症本人や家族が外出のきっかけになる活動です。喫茶、レクリエーション、勉強会など、内容や開催日もさまざまです。
                    </p>
                  </div>
                </ModalLink>
                に行くこともあります。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                季節の行事もいろいろありますよね。桜の花見を楽しむ「さくら祭り」のときには、わたしはミャンマー人の仕事仲間と民族衣装を着て踊りました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                「七夕祭り」と呼ばれる星を祭る夏の行事では、近所の児童館に通う小学生たちが15人ほどやってきて、歌や踊り、手のマッサージをしてくれました。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                マッサージはすごく人気でしたよね！
                利用者さんたちは「名前は何ですか？」と子どもたちにたくさん話しかけていました。子どもが隣にいるだけで嬉しそうでしたね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`日本語を使う生活はどうですか？`}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  介護の仕事では、コミュニケーションがすごく大事なので、日本語の勉強は必要ですね。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                ジンジンモーさんは、単語の意味はわかっているんだけど、文法で苦労しているよね。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  「に」や「を」など助詞の使い方が難しいですね。山崎さんたちにいつも助けられています。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                勉強熱心だから覚えたらすぐに使おうとするんですよ。業務日誌も日本語でしっかり書いていますよ。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                業務日誌は、その日よかったこと、感じたこと、見直したいこと、目標などを書いています。それを先輩が読んで、日本語の間違いを教えてくれます。 日本語は勉強中ですが、おしゃべりするのは好きです。話し方の目標は、利用者の梅津さん。何かお手伝いしたときには「いつもお世話をしてくれてありがとう」とすごく丁寧に話してくれるんです。わたしの憧れです。 
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`日本ではどんな生活をしていますか？`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image9.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
                <p>
                  ミャンマーは「暖かい」か「暑い」しかないので、寒い場所での暮らしはとても心配でした。コートや暖かい洋服をたくさん買ってミャンマーから持ってきたので、すごい荷物だったんですよ（笑）。札幌に着いたときは、まわりが全部真っ白な景色でびっくりしました。日本に来て初めて買ったのは、
                  <ModalLink id={`modal-4`} style={{ display: `inline-block` }}>
                    <span>
                      雪道で滑らない靴
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>雪道で滑らない靴</h3>
                      <p>
                        雪が降る地域では、暖かく、水に強くて、滑りにくい靴が必要です。滑り止めのついたゴム底の靴がおススメです。「ウィンターブーツ」や「スノーシューズ」などの名前で売られているものもあるので、店員さんに相談してください。
                      </p>
                    </div>
                  </ModalLink>
                  です。
                </p>
                <p>
                  いまは会社が用意してくれたアパートで、ミャンマー人の技能実習生と2人暮らしをしています。地下鉄に20分乗って、そのあとまた20分歩いて施設に通っています。休みの日には、必ずお母さんと電話をします。友だちと買い物に出かけたり、公園でのんびりしたりするのも好きです。
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                わたしたちは、あまり休みがあわないんですよ。でも12月にホーム長と一緒にイルミネーションを見に行ったよね！
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image11.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>雪にイルミネーションが光ってとてもきれいでしたね。</p>
              <p>
                山崎さんとの思い出といえば、誕生日プレゼントに手編みの人形をもらいました。嬉しくて、袋に入れたまま部屋に飾っています。 
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`これからの目標を教えてください。 `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                日本で介護福祉士の資格を取りたいです。ミャンマーにも介護施設はありますが、まだまだ介護職員も技術も足りません。わたしはミャンマーからの技能実習生の1期生なので、ミャンマーに帰ったら、介護の技術を教える先生になりたいと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`山崎 さん`}>
              <p>
                介護は、まだきつい仕事のイメージがありますよね。でもわたしたちはそのイメージを変えていこうとしているんです。施設に入居すると、外との関係が薄れてしまいやすいですが、わたしは中と外をつなぐ「架け橋」になりたいなと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ジンジンモー さん`}>
              <p>
                日本で介護の仕事をするには、やっぱり日本語を一生懸命勉強することが大切ですね。介護の技術は、仕事をするうちにだんだん身についていきますが、日本語は勉強しないとうまくなりません。介護の技術だけでなく、日本で働いた経験を後輩たちに伝えていきたいと思っています。
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>働いている施設</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    満快のふる郷 さくら発寒
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>働いている施設</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                「グループホーム」と呼ばれる、認知症高齢者向けの介護施設。定員は、1階・2階がそれぞれ9名ずつと小規模で、家庭的な雰囲気が特徴です。多くの利用者がキッチンのあるリビングルームでくつろいで1日を過ごします。季節ごとのイベントが充実しているほか、利用者のお出かけや地域の人々とのかかわりを重視しています。
              </p>
              <p>
                <a
                  href={`https://sakura-cs.com`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://sakura-cs.com
                </a>
              </p>
              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Mihoko Tsujita
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/3`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/ja/interview/2`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/01/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/01/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/01/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/01/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/01/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/01/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/01/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/01/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/01/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/01/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/01/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/01/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/01/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/01/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/01/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/01/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/01/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
